import React, { Component } from "react";
import { connect } from "react-redux";
import CountUp from "react-countup";

import ManagerDeposit from "./ManagerDeposit";
import CollectorDeposit from "./CollectorDeposit";
import DashboardMeter from "./DashboardMeter";
import Payment from "./Payment";
import Manager from "./Manager";
import Card from "./Card";
import Collector from "./Collector";
import ScrollingMessage from "../Scroll/ScrollingMessage";

import Modal from "../../components/modals/Modal";

import { getDashboard, payDeposit } from "../../actions/dashboardActions";

const d = new Date();
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboard: props.dashboard.isLoaded ? props.dashboard : {},
      amount: 0,
      isLoaded: false
    };
  }

  componentDidMount() {
    if (!this.props.dashboard.isLoaded) this.props.getDashboard();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dashboard.isLoaded) {
      let amount = nextProps?.dashboard?.dashboard?.manager?.balance
        ? nextProps.dashboard.dashboard.manager.balance
        : 0;

      this.setState({
        dashboard: nextProps.dashboard,
        amount,
        isLoaded: true
      });
    }
  }

  onChangeHandler = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  onSubmit = e => {
    e.preventDefault();

    const {
      balance,
      totalDeposit,
      totalCollection
    } = this.state.dashboard.dashboard.manager;

    if (this.state.amount > balance || this.state.amount < 10) {
      alert("ডিপোজিটের পরিমাণ সঠিক নয়।");
    } else {
      this.props.payDeposit({
        amount: this.state.amount,
        collection: totalCollection,
        deposit: totalDeposit,
        balance: balance
      });
    }
  };

  render() {
    const { auth } = this.props;

    let salary,
      expenditure,
      manager,
      collectors,
      collection,
      user,
      duration = 1.25,
      totalBillCollectionByCollector = 0;

    if (this.state.dashboard.isLoaded) {
      salary = this.state?.dashboard?.dashboard.salary;
      expenditure = this.state?.dashboard?.dashboard.expenditure;
      manager = this.state?.dashboard?.dashboard.manager;
      collectors = this.state?.dashboard?.dashboard.collectors;
      collection = this.state?.dashboard?.dashboard.collection;
      user = this.state?.dashboard?.dashboard.user;
      totalBillCollectionByCollector = this.state?.dashboard?.dashboard
        ?.totalBillCollectionByCollector;

      // user.expected.total = 14525415
      // manager.totalCollection = 45254140
      // manager.totalDeposit = 54848545
      // collection.total.amount = 12541452
    }

    return (
      <div className="main-content">
        <section className="section">
          <div className="container pt-4 pb-2 mb-4 bg-white text-center border-rounded">
            {auth.user.type !== "operator" ? (
              <p className="h3 font-weight-bold text-success">
                ব্যালেন্সঃ&nbsp;
                {this.state.dashboard.isLoaded ? (
                  auth.user.type === "feeder" ? (
                    <CountUp
                      start={0}
                      end={manager.totalDeposit}
                      duration={duration}
                      separator=","
                      prefix="৳ "
                    />
                  ) : (
                    <CountUp
                      start={0}
                      end={manager.balance}
                      duration={duration}
                      separator=","
                      prefix="৳ "
                    />
                  )
                ) : (
                  0
                )}
              </p>
            ) : (
              <p className="h3 font-weight-bold">ড্যাশবোর্ড</p>
            )}
          </div>
          <div className="container">
            {auth?.status?.status === "unpaid" && (
              <div
                style={{ minHeight: 230 }}
                className="row justify-content-center p-3 bg-white align-items-center"
              >
                {/* INVOICE PAYMENT */}
                <Payment status={auth.status} invoice={auth.invoice} />
              </div>
            )}
            {this.props?.server?.requestPending ? (
              <div className="fa-3x text-center mt-5">
                <i style={{ fontSize: 40 }} className="fas fa-sync fa-spin"></i>
              </div>
            ) : (
              <div
                style={{ minHeight: 230 }}
                className="row justify-content-center p-3 bg-white align-items-center"
              >
                {this.state.dashboard.isLoaded && (
                  <>
                    {/* INVOICE PAYMENT */}
                    {/* {auth?.status?.status === "unpaid" && <Payment status={auth.status} invoice={auth.invoice} />} */}

                    {/* METER */}
                    {auth.user.type !== "operator" && (
                      <DashboardMeter
                        manager={manager}
                        collection={collection}
                        user={user}
                        auth={auth}
                      />
                    )}

                    {/* Manager Deposit */}
                    {this.state.dashboard.isLoaded &&
                      auth.user.type === "feeder" &&
                      manager.pendingDeposit && (
                        <ManagerDeposit manager={manager} />
                      )}

                    {/* MANAGER */}
                    {auth.user.type !== "operator" && (
                      <Manager manager={manager} auth={auth} />
                    )}

                    {/* CARD */}
                    <Card
                      collection={collection}
                      user={user}
                      expenditure={expenditure}
                      salary={salary}
                      auth={auth}
                    />

                    <div className="col-12">
                      <hr />
                    </div>
                    {/* Collector Deoposits */}
                    {this.state.dashboard.isLoaded &&
                      auth.user.type === "manager" && (
                        <CollectorDeposit
                          collectors={collectors}
                          manager={manager}
                        />
                      )}

                    {/* Collector */}
                    {auth.user.type !== "operator" && (
                      <Collector
                        collectors={collectors}
                        totalCollection={totalBillCollectionByCollector}
                      />
                    )}

                    {/* Scroll Message  */}
                    {/* <ScrollingMessage /> */}
                  </>
                )}
              </div>
            )}
          </div>
        </section>
        <Modal
          modalTitle="ডিপোজিট প্রদান"
          onSubmit={this.onSubmit}
          pending={this.props.server.requestPending}
          id="ManagerDepositModal"
          modalWidth="w-25"
        >
          <div className="form-group">
            <input
              className="form-control"
              onChange={this.onChangeHandler}
              value={this.state?.amount}
              type="number"
              name="amount"
              min={10}
              max={
                this.state?.dashboard?.dashboard?.manager?.balance || 10000000
              }
              placeholder="ডিপোজিটের পরিমাণ"
              disabled={this.props.server.requestPending}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  dashboard: state.dashboard,
  server: state.server,
  profile: state.profile
});

export default connect(mapStateToProps, {
  getDashboard,
  payDeposit
})(Dashboard);
